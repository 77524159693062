import React, { useEffect } from "react";
import styled from "styled-components";

const MailChimpStyle = styled.div`

.subscribeNewlyListed {
    #mc_embed_signup {
        #mc_embed_signup_scroll {
            display: flex;
            /* gap: 40px; */
            justify-content: space-between;
            align-items: center;
            .heading {
                width: 100%;
                max-width: 400px;
            }

            .mailChip_css {
                width: 100%;
                max-width: calc(100% - 540px);
               
                .asterisk {
                    color: #f00;
                }
                .mc-field-group {
                    width: 100%;
                    .emailButton {
                        display: flex;
                        align-items: flex-end;
                        margin-bottom: 2px;
                        /* justify-content: center; */

                        .inputField {
                            width: 100%;
                            label {
                                padding-bottom: 10px;
                            }
                            input {
                                border-bottom-left-radius: 30px;
                                border-top-left-radius: 30px;
                                border-top-right-radius: unset;
                                border-bottom-right-radius: unset;
                                padding: 10px;
                                border: 1px solid #dddd;
                                border-right: 0;
                                /* max-width: 100%; */
                            }
                        }
                    }
                    div.mce_inline_error {
                        margin-bottom: 0;
                        background:transparent !important;
                        color: #f00;
                        padding:0;
                        font-size: 14px;
                    }
                }
                
                .buttonSection {
                    width: 100%;
                    max-width: 150px;
                    input {
                        cursor: pointer;
                        border-radius: unset;
                        padding: 10px;
                        width: 100%;
                        border-top-right-radius: 30px;
                        border-bottom-right-radius: 30px;
                        background: #014cbd;
                        color: #fff;
                        font-weight: 600;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
`;


const Subscribe = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <MailChimpStyle>
            <div id="mc_embed_shell" className="subscribeNewlyListed">
                <div id="mc_embed_signup">
                    <form action="https://usedtoolsonlineauction.us22.list-manage.com/subscribe/post?u=f5bbe757cbe84d615dc9e63c5&amp;id=9e4cc3ca43&amp;f_id=00f9cbe1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                        <div id="mc_embed_signup_scroll">
                            <div className="heading">
                                <h2>Stay up to date on newly listed tools!</h2>
                            </div>
                            <div className="mailChip_css">
                                {/* <div className="indicates-required"><span className="asterisk">*</span> indicates required</div> */}
                                <div className="mc-field-group">
                                    <div className="error_display">
                                        <div className="emailButton">
                                            <div className="inputField">
                                                <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                                                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" />
                                            </div>
                                            <div className="buttonSection">
                                                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                                            </div>
                                        </div>

                                    </div>
                                    <div id="mce-responses" className="clear">
                                        <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                                        <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                    </div>
                                    <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                                        <input type="text" name="b_f5bbe757cbe84d615dc9e63c5_9e4cc3ca43" tabIndex="-1" value="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <script type="text/javascript">
                    {`
            (function($) {
                window.fnames = new Array();
                window.ftypes = new Array();
                fnames[0]='EMAIL';
                ftypes[0]='email';
                fnames[1]='FNAME';
                ftypes[1]='text';
                fnames[2]='LNAME';
                ftypes[2]='text';
                fnames[3]='ADDRESS';
                ftypes[3]='address';
                fnames[4]='PHONE';
                ftypes[4]='phone';
                fnames[5]='BIRTHDAY';
                ftypes[5]='birthday';
            }(jQuery));
            var $mcj = jQuery.noConflict(true);
            `}
                </script>
            </div>
        </MailChimpStyle>
    )
}

export default Subscribe;